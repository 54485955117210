<template>
    <div class="challenge-invitation-container">
        <div class="avatar-info-wrapper">

            <div class="avatar-container">
                <avatar
                    :avatar="session.creatorAvatar || null"
                />
            </div>
            <div class="invitation-information">
                <div
                    v-dompurify-html="$t(
                        session.creator ? 'arenaRoute.invitationSentInformation' : 'arenaRoute.invitationReceivedInformation',
                        { opponent: opponentChallengerId, gameTitle: session.locale.alias }
                    )"
                    class="invitation-text"
                />
                <span
                    class="invitation-expiration"
                    :class="{ expiring: isExpiring }"
                >
                    {{ $t('arenaRoute.invitationExpiration', { remainingTime }) }}
                </span>
            </div>
        </div>
        <div class="invitation-actions" :class="{ withdraw: session.creator }">
            <button
                v-if="!session.creator"
                class="btn-icon"
                :class="{ disabled: remainingChallengeSessions <= 0 }"
                :disabled="remainingChallengeSessions <= 0"
                @click="onAccept"
            >
                <font-awesome-icon :icon="['fas', 'check']" class="icon-accept" />
            </button>
            <button v-if="!session.creator" class="btn-icon" @click="onDelete">
                <font-awesome-icon :icon="['fas', 'times']" class="icon-decline" />
            </button>
            <button v-if="canWithdrawSession" class="btn-withdraw" @click="onDelete">{{ $t('arenaRoute.withdraw') }}</button>
        </div>
    </div>
</template>

<script>
    import dateConversion from '@/utils/date-conversion'
    import challengeSessionService from '@/services/challenge-session-service'
    import dateUtils from '@/utils/date-utils'
    import Avatar from '@/components/Avatar'

    export default {
        components: { Avatar },
        props: {
            session: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                remainingTime: '',
                remainingTimeInterval: null
            }
        },
        computed: {
            opponentChallengerId() {
                return this.session.creator
                    ? this.session.joinerChallengerId
                    : this.session.creatorChallengerId
            },
            isExpiring() {
                const hoursFromExpiration = dateUtils.getDurationToTime(this.session.expirationDate)
                return (
                    hoursFromExpiration <= 24
                )
            },
            remainingChallengeSessions() {
                return this.$store.getters.getRemainingChallengeSessions
            },
            canWithdrawSession() {
                const hoursFromCreation = dateUtils.getDurationFromTime(this.session.createDate)
                return (
                    this.session.creator &&
                    hoursFromCreation >= 2 &&
                    hoursFromCreation < 24
                )
            }
        },
        created() {
            this.remainingTimeInterval = setInterval(() => {
                this.remainingTime = dateConversion.getRemainingTime(
                    this.session.expirationDate,
                    Date.now(),
                    true,
                    false,
                    true,
                    ' : '
                )
            }, 1000)
        },
        beforeDestroy() {
            clearInterval(this.remainingTimeInterval)
        },
        methods: {
            onAccept() {
                challengeSessionService.checkValidity({ sessionId: this.session.sessionId })
                    .then(() => {
                        this.$store.dispatch('setChallengeSessionId', this.session.sessionId)
                        this.$router.push(`/challenges/${this.session.challengeId}`)
                    })
                    .catch(e => {
                        this.displayToast(e)
                    })
            },
            onDelete() {
                challengeSessionService.deleteChallengeSession(this.session.sessionId).then(() => {
                    this.$store.dispatch('deleteChallengeSession', this.session.sessionId)
                }).catch(e => {
                    this.displayToast(e)
                })
            }
        }
    }
</script>
<style>
    .invitation-text p {
        margin-bottom: 0.25rem;
    }
</style>

<style lang="scss" scoped>
.challenge-invitation-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    background-color: var(--arena-invitation-bg-color);
    padding: 0.75rem;
    border-radius: 0.5rem;
    width: 100%;

    .avatar-info-wrapper {
        display: flex;
        gap: 1rem;
    }

    .avatar-container {
        max-width: 55px;
    }

    .invitation-information {
        font-size: 0.8rem;
        text-align: left;
        color: var(--arena-invitation-title-color);

        .invitation-expiration {
            color: var(--arena-invitation-subtitle-color);
            text-shadow: 0 0 0.15rem #000;

            &.expiring {
                color: #f60000;
            }
        }
    }

    .invitation-actions {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: 100%;
        justify-content: flex-end;

        &.withdraw {
            justify-content: flex-end;
        }

        .until-available-sessions {
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            background-color: #fffc;
            border-radius: 1.5rem;
            font-size: 0.8rem;
            border: 2px solid #fff;
        }

        .btn-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fffc;
            border-radius: 2rem;
            padding: 0.25rem;
            width: 30px;
            height: 30px;
            border: 2px solid #fff;

            &.disabled {
                background-color: var(--disabled-color);
            }

            .icon-accept {
                color: #1d7956;
            }

            .icon-decline {
                color: #f60000;
            }
        }

        .btn-withdraw {
            background-color: #fffc;
            border-radius: 1rem;
            padding: 0.25rem 0.5rem;
            color: #353e4f;
            justify-self: flex-end;
            border: 2px solid #fff;
        }
    }
}

@media screen and (min-width: 992px) {
    .challenge-invitation-container {
        flex-direction: row;

        .invitation-actions {
            width: unset;

            .until-available-sessions {
                gap: unset;
                flex-direction: column;
                padding: 0 1rem;
            }
        }
    }
}
</style>
